@import url('https://fonts.googleapis.com/css2?family=Jost&display=swap');
.infotitle, .infotext {
    line-height: 2.5vh;
    font-family: 'Jost', sans-serif;
}
.infotext {
    overflow-wrap: break-word;
}

.information {
    padding: 0.5vh 2vw;
    width: 20%;
    background-color: rgba(0, 0, 0, 0.4);
    color: rgba(255, 255, 255, 0.9);
    text-align: center;
    margin: auto;
    position: relative;
    left: 0.5vw;
}

@media only screen and (max-width: 844px) {
    .information {
        width: 50%;
    }
}

@media only screen and (max-width: 667px) {
    .infotitle, .infotext {
        font-size: 2vh;
    }
}