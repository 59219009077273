.navbutton {
    text-decoration: none;
    color: white;
    font-size: 2vw;

    border: white 1px solid;
    padding: 1vh 2vw;
}

.navbutton:hover {
    color: black;
    background-color: white;
    transition: all 0.3s ease-out;
}