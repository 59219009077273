@import url('https://fonts.googleapis.com/css2?family=Staatliches&display=swap');

html {
    background: linear-gradient( rgba(0, 0, 0, 0.7) 100%, rgba(0, 0, 0, 0.7)100%), url("https://static.vecteezy.com/system/resources/previews/002/949/141/non_2x/programming-code-coding-or-hacker-background-vector.jpg");
    overflow-y: hidden;
}

body {
    color: white;
    font-size: 20px;
    font-family: 'Staatliches', cursive;
}

.title {
    color: white;
    font-size: 8vw;
    text-align: center;
}

.subtitle {
    font-size: 5vw;
    text-align: center;
    position: relative;
    bottom: 4vh;
}

.title, .subtitle {
    line-height: 0;
}

.name {
    color: red;
}

#buttons {
    list-style-type: none;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.discord > img {
    width: 5%;
}

.discord > span {
    position: relative;
    bottom: 1vh;
}

.twitter > img {
    width: 5%;
}

.twitter > span {
    position: relative;
    bottom: 1vh;
}

#info {
    text-align: center;
    list-style-type: none;
    display: flex;
    flex-direction: row;
}

#footer {
    text-align: center;
    list-style-type: none;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}
a {
    text-decoration: none;
    color: white;
}

a:hover {
    color: gray;
}

@media only screen and (max-width: 844px) {
    .title, .subtitle {
        line-height: 1em;
    }


    .discord > img, .twitter > img {
        width: 20%;
    }

    #buttons {
        position: relative;
        right: 1em;
    }
}
@media only screen and (max-width: 667px) {
    .title, .subtitle {
        line-height: 1em;
    }
    .discord > img, .twitter > img {
        width: 15%;
    }
    #footer {
        position: relative;
        right: 1em;
        font-size: 2.5vh;
    }
}